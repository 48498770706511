import { api } from "api/core";

import type { CitationDetailsDto } from "./widget/dtos/citationDetailsDto";
import type { MessageCitationDto } from "./widget/dtos/messageCitationDto";

const baseUrl = "/v1/ai";

interface ThreadRunCreatedStreamLine {
  event: "thread.run.created"; // discriminator
  data: {
    threadId: string;
  };
}

interface ThreadMessageCreatedStreamLine {
  event: "thread.message.created"; // discriminator
  data: {
    id: string;
    createdAt: number;
    role: "user" | "assistant";
    delta: {
      content: Array<{
        text: {
          value: string;
        };
        type: "text";
      }>;
    };
  };
}

interface ThreadMessageDeltaStreamLine {
  event: "thread.message.delta"; // discriminator
  data: {
    delta: {
      content: Array<{
        text: {
          value: string;
        };
        type: "text";
      }>;
    };
  };
}

interface ThreadCitationsContentStreamLine {
  event: "citations.content"; // discriminator
  data: {
    citations: Array<MessageCitationDto>;
  };
}

interface ThreadCitationsRefStreamLine {
  event: "citations.ref"; // discriminator
  data: {
    refs: string;
  };
}

export type AiAssistantStreamLine =
  | ThreadRunCreatedStreamLine
  | ThreadMessageCreatedStreamLine
  | ThreadMessageDeltaStreamLine
  | ThreadCitationsContentStreamLine
  | ThreadCitationsRefStreamLine;

interface SendMessageParams {
  threadId: string;
  queryId: string;
  widgetId: string;
  message: string;
  activeEnv: string;
  viewMode: boolean;
}

export const sendMessage = async (
  params: SendMessageParams,
): Promise<Response> => {
  const headers = new Headers({
    "Content-Type": "application/json",
    "X-Requested-By": "Appsmith",
    Accept: "text/event-stream",
    "X-Appsmith-EnvironmentId": params.activeEnv,
  });

  const requestBody = {
    requestType: "SEND_MESSAGE_STREAM",
    config: {
      threadId: params.threadId,
      resourceId: params.widgetId,
      actionId: params.queryId,
    },
    message: params.message,
    viewMode: params.viewMode,
  };

  const request = new Request(`/api${baseUrl}/invoke`, {
    method: "POST",
    headers: headers,
    body: JSON.stringify(requestBody),
  });

  return fetch(request);
};

interface FetchCitationParams {
  queryId: string;
  citationId: string;
  messageId: string;
  threadId: string;
  viewMode: boolean;
}

export const fetchCitation = async ({
  citationId,
  messageId,
  queryId,
  threadId,
  viewMode,
}: FetchCitationParams) => {
  const response = await api.get<{ data: CitationDetailsDto }>(
    `${baseUrl}/citation`,
    {
      params: {
        citationId,
        messageId,
        threadId,
        actionId: queryId,
        viewMode,
      },
    },
  );

  return response;
};
